*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family:Georgia, 'Times New Roman', Times, serif;
  }
  
  body {
    margin: 0;
    padding: 0;
  }